import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from '../components/layout/gallery';
import SingleImage from '../components/layout/single-image';
import LightboxPageLayout from '../components/layout/lightbox-page-layout';
export const query = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/2021" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
    allJson(filter: { graphql: { eq: "EF2021" } }) {
      nodes {
        resources {
          index
          title
          image {
            base
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = LightboxPageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Erre fordítjuk, amit adsz (2021)`}</h1>
    <h2>{`Noé állatotthon`}</h2>
    <p>{`A 2022-ben pont 30 éve működő NOÉ Állatotthon jelenleg már bőven több, mint 1200 szőrös, tollas, kopasz állat ideiglenes vagy végleges otthona. Számunkra mindegy, hogy pici cuki, vagy nagy morgós, fehér vagy fekete, szép vagy csúnya, baba vagy matuzsálem, az állatainkban egy biztosan közös, hogy bajba kerültek és mentésre szorultak. Kutyák, cicák, gazdasági állatok, vadon élő fajok, egzotikus állatok, mind olyanok, akik emberi segítségre szorulnak felnevelésük, gyógyulásuk vagy akár egész életük idejére.`}</p>
    <p>{`Sajnos a COVID-19 világjárvány a mi életünkben is bőven hozott kihívásokat. Az év első felében zárt kapuk mögött működtünk. Nem jöhettek a látogatók, elmaradtak a programok, nem volt kutyasétáltatás és nem tudtunk külső helyszínekre sem menni. Az örökbeadások a megváltozott körülmények mellett is zajlottak, bár sajnos az év második felében érezhető volt némi visszaesés.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(0, 3)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Továbbra is büszkén vállaljuk, hogy az örökbefgadás a NOÉ Állatotthontól szabályokhoz kötött. Sok esetben többkörös ismerkedés szükséges, van, akiket házhoz viszünk és a kapcsolattartás alapvető elvárás. Bár visszatérően ér minket emiatt kritika, mi akkor is ragaszkodunk ehhez. Hiszen az általunk megmentett, meggyógyított, sok esetben cumisüvegből felnevelt állatoktól nem szabadulni akarunk, hanem végleges, felelős családokat keresünk a számukra.`}</p>
    <p>{`Minden évnek megvan a maga kiemelt kihívása. A 2021-es év nagyon hasonló volt az előzőhöz. A zárt kapuk mögötti működés és a pandémia mellett meredeken emelkedik a szívféreggel fertőzött kutyák száma. Ott tartunk, hogy több mint 80 aktív kezelésre szoruló szívférges kutyánk van és köztük több az extrém súlyos eset is. Sőt sajnos már az 1 év alatti kölykök is érintettek.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(3, 6)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Nem szeretnénk elkiabálni, de az új, modern baba-mama karanténunknak és a szigorú karanténozási szabályainknak, valamit az új oltási protokollnak köszönhetően, mintha a kutyusaink esetében a parvo visszább szorult volna. Ugyanez sajnos nem elmondható a kiscicákról, ahol a panleukopenia, vagyis a cicaparvo továbbra is szedi áldozatait. A 2021-es év egyik megkeserítője a giardia és az isospora nevű egysejtű paraziták, mely gyakorlatilag minden állatfajt érintenek. A kezelés nehéz és drága, az arra fogékonyak akár hetekig, hónapokig is vesztegzár alá kerülnek miatta.`}</p>
    <p>{`Ami nem változik, az a rengeteg sérült, balesetes állat. Sokszor akár napokig fekszenek segítség nélkül az utak mellett az árokban, vagy ha haza is vonszolják magukat, a tulajdonosuk egyszerűen fogja és bénán, vérző testtel, súlyosan megsérülve visszaköti őket a lánc végére. A gyepmesteri telepek tömve vannak, a civil szervezetek próbálják üríteni ezeket a sok esetben haláltáborokat, de szinte képtelenség ezzel lepést tartani. Naponta sok bejelentés, segítségkérés érkezik, a szívünk megszakad, hogy képtelenség mindenkin segíteni. Azért minden héten minimum 2 súlyosan beteg vagy sérül állat érkezett az ország egész területéről. Mindennaposak voltak a csontműtétetek, lágyszöveti sérülések és beavatkozások, szemészeti és bőrgyógyászati problémák. Súlyos parazita fertőzések, vírusos megbetegedések. Felsorolni is lehetetlen milyen és mennyi állatorvosi segítséget igénylő eset került hozzánk. Az állatorvosi és gyógyszer, gyógytáp költségünk havonta meghaladja a 6 millió forintot.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(6, 9)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Rengeteg baba és kölyökállat érkezik. Kutyusaink baba-mama karanténja folyton tele és a várólista végtelen hosszú. Babacicákat kizárólag elhivatott ideiglenes befogadóinkhoz tudunk elhelyezni, akik folyamatosan teltházzal segítenek nekünk. Az egyik alom még ki sem repült és máris cumiztatják a következőket. Bár egyre több helyen elérhetőek a különböző ivartalanítási akciók, sőt már az ingyen lehetőségek is szinte az egész országban elérhetőek, mégis rengeteg a nemkívánt szaporulat, akik megszületésük pillanatában mentésre szorulnak.`}</p>
    <p>{`2021-ben 1134 állat került a gondozásunkba, ebből 804 kutya vagy cica. A többiek baromfik, disznók, kecskék, birkák, szarvasmarha, sérült madarak, vagy egyéb vadonélő fajok egyedei, sőt nem várt csomagként az egyik reggel egy 7 tagú nutria család várt bedobozolva minket a kapuban. 756 kutyát és cicát sikerült új családba örökbeadnunk. A vadonélő fajok egyedeit, ha egészségi állapotuk megengedi, akkor gyógyulásuk után szabadon engedjük, sokan önszántukból maradnak a NOÉ Állatotthon közelében. A gazdasági állatok és azok akik nem repatriálhatóak pedig végleges lakóinként élik tovább velünk az életüket.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(9, 12)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2021`}{`.`}{` a nagy összefogások, a beadványok készítésének, egyeztetéseknek az éve is volt. És bizony vannak eredmények, vannak sikerek és bíztató jelek. Nyáron megalakult az Állatvédők Érdekvédelmi és Szakmai Szövetsége, melynek a NOÉ Állatotthon Alapítvány alapító tagja. A szövetség céljai között szerepel többek között, az állatvédelemmel kapcsolatos legégetőbb problémák felkarolása és a megfelelő hatóságok és döntéshozók felé történő javaslatok benyújtása.`}</p>
    <p>{`A jogalkotók részéről 3 nagyon fontos jogi csomag került elfogadásra, melyek mindegyikében konzultációs szerepet kaptunk. Többek között bekerült a Büntető Törvénykönyvbe a szaporítás fogalma és büntethetősége, az állatviadalok résztvevőinek büntethetősége, a visszaesők komolyabb büntethetősége. Megszületett az évek óta nagyon várt "kóborállatrendelet", mely elsősorban az iszonyú körülmények között működő gyepmesteri telepek működését és a bekerülő állatok „kezelését” szabályozza. Valamint az év utolsó napjaiban jogszabályba foglalták, hogy az állatvédelem 1,5milliárd forintos támogatásban részesül 2022-ben.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(12, 15)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Bár továbbra is nagyon kell figyelnünk, hogy az alapműködésünkön túl milyen „extra” kiadásokat engedhetünk meg magunknak, de néhány nagyon fontos fejlesztésre, modernizációra sikerült kigazdálkodnunk a keretet, vagy finanszírozást találnunk.`}</p>
    <p>{`Megújult a BullBox részlegünk egyik oldala, mely nagyjából 10 éve egy konténerből lett kialakítva. Sajnos eljárt már felette az idő, a védencek és a fertőtlenítő szerek vasfoga és nem várhatott tovább a felújítása. A speciális elhelyezésre szoruló kutyák egy hőszigetelt, csempézett és rozsdamentes rácsokkal ellátott, csodaszép, modern és nagyon jól fertőtleníthető részleget vehettek bírtokba. A felújítás költségeinek egy részét, a Miniszterelnöki Kabinetiroda által nyújtott támogatásból finanszíroztuk. Köszönjük!`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(15, 18)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Nagyjából 30 éves vágyunk már egy szénatároló, mely alatt végre védetten és biztonságban tudhatjuk a növényevőink fő táplálékát, a drága szénát. Minden évben próbáljuk a legjobb minőséget beszerezni számukra, de utána állandó fejfájást okoz, hogy miként tudjuk megvédeni az esőtől, nehogy penészes legyen és tönkre menjen. 2021-ben végre belevághattunk ez elkészítésébe és az évvégére már áll a hatalmas felépítmény fém váza a beton alapon. A befejezése ugyan 2022-es projekt lesz, de mind méretében, mind fontosságában egy óriási fejlesztés ez nekünk.`}</p>
    <p>{`Tovább korszerűsödött a baba-mama karantén, elkészült és munkába állt az összes férőhely. Felkerültek a hővédő fóliák, melyek az ózonos fertőtlenítésben is nagy szerepet kapnak. Miután rengeteg a bekerülő kölyök, így ez az egyik legkiemeltebb karantén részlegünk.`}</p>
    <p>{`Beázás elleni védelemként elkészült a cicás irodakonténer tetőszerkezete. Az alapanygok nagy részét már korábban beszereztük, hála egy nagylelkű támogatónknak. Mivel a munkálatokat saját magunk csináltuk most is, így ez a rész most készülhetett el.`}</p>
    <p>{`A meglepetés csomagként érkezett nutriacsalád speciális igényeinek megfelelően átalakult a régi majomkifutó külső tere és kaptak egy csodás, vízforgatós medencét, melyet mind ők, mind a bent cseperedő kacsák is boldogan vettek birtokba.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(18, 21)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Megújult a zöldségelőkészítő beton aljzata is. Itt válogatjuk, tisztítjuk, aprítjuk növényevőink részére a nekik szánt élelmet. Sajnos a korábbi beton már nem bírta a terhelést, megrepedt és felpattogzott, ezért azt fel kellett vésni és új betonnal kellett ellátni.`}</p>
    <p>{`Belevágtunk egy vizesblokk kialakításába a hátsó részen. Eddig itt csak mobil wc-t tudtunk biztosítani a látogatóknak, most viszont elkezdtünk kialakítani egy stabil mosdóhelyiséget. Terveink szerint pelenkázó és mozgássérült wc is kialakításra kerül majd. 2021-ben az alapokat tudtuk megcsinálni, de nagyon szeretnénk, hogy a következő rendezvényszezonra elkészüljön.`}</p>
    <p>{`És persze folyamatosan javítjuk, cseréljük, a közel 30 éves kennelsorainkat, kifutóinkat, hogy a nálunk rövidebb vagy hosszabb időt eltöltő állatok kényelemben, biztonságban lehessenek.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(21, 24)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Persze most is tele vagyunk tervekkel, vannak amelyeket már régóta dédelgetünk és mindig jönnek újabb álmok. Elsősorban szeretnénk befejezni a megkezdet projekteket, de nagyon szeretnénk egy modern, jól takarítható galambdúcot a mostani galambos faház helyére. Külső karanténunk felújítása, modernizálása is megoldásra vár, ahogy a felnőtt karantén is. Bővíteni szeretnénk a cicás karantén és gyengélkedő részlegünket. És óriási tervünk egy teljesen új, modern kórházépület felépítése.`}</p>
    <p>{`Imádott menhelysegítő programunk a világjárvány miatt most elsősorban élelem, gyógyszer és egyéb hasznos adományok biztosításában tudott tovább élni. Több ezer kg kutya és cicatáppal tudtuk egész évben folyamatosan segíteni a kisebb menhelyeket. Gyógyszerek, gyógyászati segédszközök kerültek kiosztásra igény szerint.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(24, 27)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Volt sok szívszorító eset, amikor magunk sem hittük a látottakat. A bekerülő és a nálunk élő védenceinkről szóló hírek elolvashatóak ITT: `}<a href="http://www.noeallatotthon.hu/index.php?pid=2&tid=4" target="_blank" rel="noreferrer">{`Történetek Állatainkról`}</a></p>
    <p>{`2022`}{`.`}{` január 1-én az alapítvány védenceinek száma 1.281 volt.`}</p>
    <p>{`Hálásan köszönjük támogatóinknak, önkénteseinknek a rengeteg segítséget. Köszönjük, hogy annak ellenére, hogy a múlt év nagyrészében távol voltunk egymástól mégis kitartanak mellettünk és a védenceink mellett. Évről évre a biztos alaphátteret a személyi jövdelemadójukat felajánlók biztosítják nekünk, amiért szintén nagyon hálásak vagyunk. Arra kérünk mindenkit, hogy ne hagyják veszni ezt a támogatási lehetőséget és rendelkezzenek 2022. május 20-ig személyi jövedelemadójuk 1%-ról!`}</p>
    <p><strong parentName="p">{`MERT A CSODÁKAT EGYÜTT CSINÁLJUK!`}</strong></p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(27, 30)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Sok minden megvalósult, sok dolog még álom. De érezve a támogatást, a bizalmat, nem dobjuk el az álmainkat a terveinket. Szépen sorban haladunk, tervezünk, számolunk. És talán egyszer ennek a listának is a végére érünk. De addig még egy-két apróságot megvalósítanánk. Ami a legfontosabb, hogy a megkezdett úton haladva szeretnénk biztosítani a védenceik biztos jövőjét, a kényelmüket, jólétüket, egészségügyi ellátásukat.`}</p>
    <p>{`Köszönjük mindenkinek, aki hozzájárult a munkánkhoz, önkéntesként, támogatóként, cégként vagy magánszemélyként, a személyi jövedelemadója 1%-nak felajánlásával, adománnyal, virtuális örökbefogadással, örökbefogadással, ideiglenes befogadással, az adomány vonal hívásával, vagy akár csak egy megosztással!`}</p>
    <p><strong parentName="p">{`CSAK VELETEK SIKERÜLHET!`}</strong></p>
    <SingleImage resources={[props.data.allJson.nodes[0].resources[30]]} nodes={props.data.allFile.nodes} mdxType="SingleImage" />
    <p>{`Korábbi beszámolóink:`}</p>
    <p>
  <Link to="/erre-forditjuk-2020" mdxType="Link">Erre fordítjuk - 2020</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2019" mdxType="Link">Erre fordítjuk - 2019</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2018" mdxType="Link">Erre fordítjuk - 2018</Link>
    </p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      